import React, { useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { services } from "../../../data/services"
import emailjs from "@emailjs/browser"

const ContactForm = () => {
  const form = useRef()

  const data = useStaticQuery(graphql`
    query ContactInfos {
      site {
        siteMetadata {
          contact
          title
          address
          addressLink
        }
      }
    }
  `)

  const { title, address, addressLink, contact } = data.site.siteMetadata

  const [selectedService, setSelectedService] = useState("")

  const handleRadioChange = e => {
    e.preventDefault()
    console.log(e.target.value)
    setSelectedService(e.target.value)
  }

  const sendEmail = e => {
    e.preventDefault()
    console.log("SENT")
    emailjs
      .sendForm(
        "service_mlnnt8f", // service id
        "template_5me3byk", // template id
        form.current,
        "b0v1E0WFkjklEUycV" // public key
      )
      .then(
        result => {
          console.log(result.text)
          console.log("message sent")
          e.target.reset()
        },
        error => {
          console.log(error.text)
          e.target.reset()
        }
      )
  }

  return (
    <div className="py-12 pt-16 px-8 text-center mx-auto lg:w-1/2 md:w-3/4 w-full">
      <div className="flex flex-wrap -m-2">
        <form ref={form} className="w-full mx-auto" onSubmit={sendEmail}>
          {/* Name */}
          <div className="flex flex-col justify-start pt-8 w-full mx-auto">
            <label
              htmlFor="name"
              className="inline-block mb-2 font-medium text-xl text-white text-start"
            >
              Name
            </label>
            <input
              type="text"
              required
              id="name"
              name="name"
              placeholder="John Doe"
              className="block p-2.5 shadow-sm appearance-none transition duration-400
                  w-full text-lg  bg-kinzica-dark text-white
                  rounded-lg border-2
                  focus:border-kinzica-pink outline-none"
            />
          </div>

          {/* Email */}
          <div className="flex flex-col justify-start pt-8 w-full mx-auto">
            <label
              htmlFor="email"
              className="inline-block mb-2 font-medium text-xl text-white text-start"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="john@doe.com"
              className="block p-2.5 shadow-sm appearance-none transition duration-400
                  w-full text-lg bg-kinzica-dark  text-white
                  rounded-lg border-2 border-gray-300
                  focus:border-kinzica-pink focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="flex flex-col justify-start pt-8 w-full mx-auto">
            <label
              htmlFor="message"
              className="text-start inline-block mb-2 font-medium text-xl text-white"
            >
              Message
            </label>
            <textarea
              name="message"
              rows="4"
              required
              className="block p-2.5 shadow-sm appearance-none transition duration-400
              w-full text-lg  bg-kinzica-dark text-white
              rounded-lg  border-2 border-gray-300
              focus:border-kinzica-pink focus:outline-none focus:shadow-outline"
              placeholder="Your message here..."
            ></textarea>
          </div>

          <div className="pt-8 lg:w-1/4 w-full mx-auto">
            <button
              type="submit"
              className="
              bg-kinzica-pink text-2xl py-3 px-8 hover:opacity-95 hover:text-white rounded mx-2"
            >
              Send
            </button>
          </div>
        </form>

        {/* Other contatcs */}
        <div className="p-2 w-full mx-auto pt-8 pb-24 mt-8 border-t border-gray-200 text-center">
          <a
            href={"mailto:" + contact}
            className={`text-white hover:text-kinzica-pink text-xl`}
          >
            {contact}
          </a>
          {/* <a href={addressLink} target='_blank'>
            <p className="leading-normal my-5 text-white hover:text-kinzica-pink  text-xl">{address}</p>
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default ContactForm
