import React, {useEffect} from "react"
import Seo from "../components/Layout/Seo"
import Layout from "../components/Layout/Layout"
import ContactForm from "../components/Components/Contact/ContactForm"
import { PageTitle } from '../components/Components/Headings/PageTitle';



const Contacts = ({ data }) => {



  useEffect(() => {
    window.scrollTo(0,0)
}, [data])

  return (
    <Layout>
      <Seo
        title="Kinzica Studio | Contact us"
        description="Kinzica Studio is a web design and development company located in London. Contact us to discover our services"
        url="www.kinzicastudio.com/about"
        keywords={[
          "Kinzica Studio About us", "Kinzica Studio Ruben Sonnoli", "Kinzica studio Dario Ferrante",
          "Kinzica Studio Music studio",
        ]}
      />
      <PageTitle title={"Contact us"} subtitle="Let us know which service you wanna receive"/>

      <ContactForm/>
    </Layout >

  )
}

export default Contacts;
